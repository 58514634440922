import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import dataHelper from "../helpers/dataHelper";
import { useNavigate } from "react-router";
import userHelper from "../helpers/userHelper";
import MealList from "./parts/MealList";

const PortalNutritionPlanningModule = () => {
  const [loaded, setLoaded] = useState(false);
  const [selectedClient, setSelectedClient] = useState(``);
  const [clientList, setClientList] = useState([]);
  const [fullList, setFullList] = useState([]);
  const [nutritionPlan, setNutritionPlan] = useState(null);
  const [mealData, setMealData] = useState(null);
  const [count, setCount] = useState(0);
  const [previousState, setPreviousState] = useState(null);
  const [lastCopyTarget, setLastCopyTarget] = useState(null);
  const [lastCopyState, setLastCopyState] = useState(null);
  const [undoButtonLoader, setUndoButtonLoader] = useState(false);
  const [undoCopyLoader, setUndoCopyLoader] = useState(false);
  const [clientView, setClientView] = useState(false);
  const [viewLoader, setViewLoader] = useState(false);
  const [nutritionNotes, setNutritionNotes] = useState([]);

  const [displayMoreNotes, setDisplayMoreNotes] = useState(false);
  // const [displayMoreNotesText, setDisplayMoreNotesText] = useState('More');
  const [displayMoreNotesCount, setDisplayMoreNotesCount] = useState(0);

  const nav = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    try {
      document.querySelector(`#selectclient`).value = selectedClient;
    } catch (err) {
      return;
    }
  }, [loaded]);

  const fetchData = async () => {
    const uman = new userHelper();
    const response = await uman.getClientList();
    const result = await response.json();
    const response3 = await uman.getFullList();
    const result3 = await response3.json();
    if (result.status != `ok` || result3.status != `ok`) {
      nav(`/login`);
    }
    setClientList(result.data);
    setFullList(result3.data);
    setLoaded(true);
  };

  const fetchNutritionPlan = async () => {
    const client = document.getElementById(`selectclient`).value;
    setLoaded(false);
    const dman = new dataHelper();
    const response = await dman.getNutritionPlan(client);
    const result = await response.json();
    if (result.status != `ok`) {
      nav(`/login`);
    } else if (result.data) {
      setNutritionPlan(result.data);
      setSelectedClient(client);
      setMealData(JSON.parse(result.data.plan));
      setCount(JSON.parse(result.data.meals).quantity);
      setClientView(false);
    } else {
      setSelectedClient(client);
      setClientView(false);
    }
    
const dateRegex = /^(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01])-([0-9]{2}|[0-9]{4})$/;

const nutritionNotesRes = await dman.getNutritionNotesByUserId(client);
const nutritionNotesResult = await nutritionNotesRes.json();
if (nutritionNotesResult.status == `ok`) {
      setNutritionNotes(
        nutritionNotesResult.data
          .filter(note => {
            return dateRegex.test(note.weekOf ?? '');
          })
          .sort((a, b) => (new Date(a.weekOf)) - (new Date(b.weekOf)))
          .reverse()
      );
      setTimeout(() => {
        setLoaded(true);
      }, 500);
    }
  };

  const populateClientBox = () => {
    let payload = [];
    clientList.forEach((client) => {
      payload.push(genTag(client._id, client.fname, client.lname));
    });
    return payload;
  };

  const genTag = (id, fname, lname) => {
    return (
      <option value={id}>
        {fname} {lname}
      </option>
    );
  };

  const handleCount = () => {
    setCount(document.getElementById("meals-quantity").value);
  };

  function handleAddNutritionNote() {
    return () => {
      setNutritionNotes([{ note: (nutritionNotes[0] ?? { note: '' }).note, weekOf: undefined }, ...nutritionNotes])
    }
  }

  async function handleSaveNutritionNote(nutritionNoteObj) {
    try {

      const enteredWeekOf = prompt('Please enter a Week-Of date', nutritionNoteObj.weekOf ?? (new Date()).toLocaleDateString().replaceAll('/', '-'));

      const clientId = document.getElementById(`selectclient`).value;
      const dman = new dataHelper();

      if (!nutritionNoteObj._id) {
        //Create New Note
        const res = await dman.createNutritionNote(nutritionNoteObj.note, enteredWeekOf, clientId);
        const result = await res.json();
        if (result.status != 'ok') {
          alert('error creating new item');
          throw new Error('error creating new item');
        }
        return;
      }

      //Update New Note
      const res = await dman.updateNutritionNote(nutritionNoteObj.note, enteredWeekOf, nutritionNoteObj._id);
      const result = await res.json();
      if (result.status != 'ok') {
        alert('error update note');
        throw new Error('error updating note');
      }

    } catch (err) {
      console.error(err);
    } finally {
      fetchNutritionPlan()
    }
  }

  async function handleDeleteNutritionNote(nutritionNoteObj) {
    const dman = new dataHelper();
    try {
      const areYouSure = confirm(`Please confirm you want to delete note with week-of ${nutritionNoteObj.weekOf}`);

      if (!areYouSure) {
        return;
      }

      const res = await dman.deleteNutritionNote(nutritionNoteObj._id);
      const result = await res.json();

      if (result.status != 'ok') {
        alert('Error deleting note');
        throw new Error('Deleting Note');
      }

    } catch (err) {
      console.error(err);
    } finally {
      fetchNutritionPlan();
    }
  }

  const saveNutritionPlan = async () => {
    if (!!nutritionPlan) {
      setPreviousState({
        macros: JSON.parse(nutritionPlan.macros),
        meals: JSON.parse(nutritionPlan.meals),
        fats: JSON.parse(nutritionPlan.fats),
        plan: JSON.parse(nutritionPlan.plan),
        notes: nutritionPlan.notes,
      });
    }
    let macros = {
      protein: document.getElementById("macro-protein").value,
      carbs: document.getElementById("macro-carbs").value,
      fats: document.getElementById("macro-fats").value,
    };
    let meals = {
      quantity: document.getElementById("meals-quantity").value,
      proteinEach: document.getElementById("meals-protein").value,
      carbsEach: document.getElementById("meals-carbs").value,
      fatsEach: document.getElementById("meals-fats").value,
    };
    let fats = {
      oils: document.getElementById("fats-oils").value,
      nuts: document.getElementById("fats-nuts").value,
      nb: document.getElementById("fats-nutbutters").value,
      avocado: document.getElementById("fats-avocado").value,
      butter: document.getElementById("fats-butter").value,
    };
    let plan = {
      protein: [],
      carbs: [],
      fats: [],
    };

    //gather elements
    const proteins = document.getElementsByName("pid");
    proteins.forEach((protein) => {
      plan.protein.push(protein.value);
    });
    const carbs = document.getElementsByName("cid");
    carbs.forEach((carb) => {
      plan.carbs.push(carb.value);
    });
    const fatEls = document.getElementsByName("fid");
    fatEls.forEach((fat) => {
      plan.fats.push(fat.value);
    });
    const notes = document.getElementById(`notes`).value;

    const d = new dataHelper();
    const response = await d.updateNutritionPlan(
      selectedClient,
      notes,
      macros,
      meals,
      fats,
      plan
    );
    const body = await response.json();
    const { msg } = body;
    alert(msg);
    fetchNutritionPlan();
  };

  const assignBlankPlan = async () => {
    if (!!nutritionPlan) {
      setPreviousState({
        macros: JSON.parse(nutritionPlan.macros),
        meals: JSON.parse(nutritionPlan.meals),
        fats: JSON.parse(nutritionPlan.fats),
        plan: JSON.parse(nutritionPlan.plan),
        notes: nutritionPlan.notes,
      });
    }
    let macros = {
      protein: "",
      carbs: "",
      fats: "",
    };
    let meals = {
      quantity: "",
      proteinEach: "",
      carbsEach: "",
      fatsEach: "",
    };
    let fats = {
      oils: "",
      nuts: "",
      nb: "",
      avocado: "",
      butter: "",
    };
    let plan = {
      protein: [],
      carbs: [],
      fats: [],
    };
    const notes = "";
    const d = new dataHelper();
    const response = await d.updateNutritionPlan(
      selectedClient,
      notes,
      macros,
      meals,
      fats,
      plan
    );
    const body = await response.json();
    const { msg } = body;
    alert(msg);
    fetchNutritionPlan();
  };

  const populateClientBoxAll = () => {
    let payload = [];
    fullList.forEach((client) => {
      payload.push(genTag(client._id, client.fname, client.lname));
    });
    return payload;
  };

  const copyPlan = async () => {
    const from = document.querySelector(`#selectfrom`).value;
    const to = document.querySelector(`#selectto`).value;
    setLastCopyTarget(to);
    const dman = new dataHelper();
    const response2 = await dman.getNutritionPlan(to);
    const result2 = await response2.json();
    setLastCopyState(result2.data);
    console.log(result2.data);
    const response = await dman.copyNutritionPlan(from, to);
    const result = await response.json();
    alert(result.msg);
    fetchNutritionPlan();
  };

  const undoAction = async () => {
    setUndoButtonLoader(true);
    if (!!previousState) {
      const d = new dataHelper();
      const response = await d.updateNutritionPlan(
        selectedClient,
        previousState.notes,
        previousState.macros,
        previousState.meals,
        previousState.fats,
        previousState.plan
      );
      const result = await response.json();
      if ((result.status = `ok`)) {
        alert(`Nutrition Plan reverted`);
      } else {
        alert(result.msg);
      }
      fetchNutritionPlan(selectedClient);
    } else {
      alert("There is no action to revert");
    }
    setUndoButtonLoader(false);
  };

  const undoCopy = async () => {
    setUndoCopyLoader(true);
    if (!!lastCopyState) {
      const d = new dataHelper();
      const response = await d.updateNutritionPlan(
        lastCopyTarget,
        lastCopyState.notes,
        lastCopyState.macros,
        lastCopyState.meals,
        lastCopyState.fats,
        lastCopyState.plan
      );
      const result = await response.json();
      if ((result.status = `ok`)) {
        alert(`Nutrition Plan reverted`);
      } else {
        alert(result.msg);
      }
      fetchNutritionPlan(selectedClient);
    } else {
      alert("There is no action to revert");
    }
    setUndoCopyLoader(false);
  };

  const changeView = (n) => {
    setViewLoader(true)
    setClientView(n)
    setTimeout(() => {
      setViewLoader(false)
    }, 300)
  }

  function handleChangeShowMore() {
    return () => {
      // if (displayMoreNotes) {
      //   setDisplayMoreNotes(false);
      //   setDisplayMoreNotesText('More')
      //   return;
      // }
      // setDisplayMoreNotes(true);
      // setDisplayMoreNotesText('Less')
      setDisplayMoreNotesCount(displayMoreNotesCount + 1);
    }
  }

  if (loaded) {
    return (
      <div className="portalNutritionPlanningModule">
        <h2>Nutrition Planning</h2>
        <p>
          Use the Nutrition Planning module to create, view, edit, and assign
          nutrition plans to clients.
        </p>
        <h3>Select Client</h3>
        <select id="selectclient" onChange={fetchNutritionPlan}>
          <option value="">SELECT A CLIENT</option>
          {populateClientBox()}
        </select>
        <button onClick={saveNutritionPlan}>Save Nutrition Plan</button>
        <button onClick={assignBlankPlan}>Assign Blank Plan</button>
        <button onClick={undoAction}>
          {undoButtonLoader ? <Loading /> : "Undo"}
        </button>
        <h3>Plan Templates</h3>
        <p>Copy from:</p>
        <select id="selectfrom">
          <option value="">SELECT A CLIENT</option>
          {populateClientBoxAll()}
        </select>
        <p>Copy to:</p>
        <select id="selectto">
          <option value="">SELECT A CLIENT</option>
          {populateClientBox()}
        </select>
        <button onClick={copyPlan}>Copy Nutrition Plan</button>
        <button onClick={undoCopy}>
          {undoCopyLoader ? <Loading /> : "Undo"}
        </button>

        <label htmlFor="clientView">Client View</label>
        <select id="clientView" name="clientView" onChange={(e) => { changeView(e.target.value) }}>
          <option value='false'>False</option>
          <option value='true'>True</option>
        </select>

        <h3>Nutrition Plan</h3>
        {nutritionPlan && !viewLoader ? (
          <>
            {clientView === 'true' ? (
              <>
                <h3>Notes</h3>

                <p>General Program Notes:</p>
                <textarea
                  className="notes"
                  id="notes"
                  placeholder="notes"
                  defaultValue={nutritionPlan.notes}
                  readOnly
                  style={{height: 450}}
                />

                <button type="button" style={buttonStyles} onClick={handleAddNutritionNote()}>Add Note</button>
                {
                  displayMoreNotes
                    ? (nutritionNotes ?? []).map((weeklyNote, noteIdx) => {
                      return (
                        // <NoteEntryBox key={`${weeklyNote._id}`} providedNoteObj={weeklyNote} emitSaveNote={handleSaveNutritionNote} emitDeleteNote={handleDeleteNutritionNote} />
                        <textarea readOnly style={noteEntryBoxStyles}>{weeklyNote.note}</textarea>
                      )
                    })
                    :
                    nutritionNotes.length == 0
                      ? <p>Client has no Nutrition Notes</p>


                      : (nutritionNotes ?? []).map((weeklyNote, noteIdx) => {
                        if (noteIdx > 1 + displayMoreNotesCount * 4) {
                          return;
                        }
                        return (
                          // <NoteEntryBox key={`${weeklyNote._id}`} providedNoteObj={weeklyNote} emitSaveNote={handleSaveNutritionNote} emitDeleteNote={handleDeleteNutritionNote} />
                          <div style={{width: '100%'}}>
                            <p>Week of: {weeklyNote.weekOf}</p>
                            <textarea readOnly style={noteEntryBoxStyles}>{weeklyNote.note}</textarea>
                          </div>
                        )
                      })

                }
                {
                  (nutritionNotes.length > 2 && nutritionNotes.length > (displayMoreNotesCount) * 4 + 2)
                    ? <button type="button" style={buttonStyles} onClick={handleChangeShowMore()}>{/*displayMoreNotesText*/ "More"}</button>
                    : <></>
                }
                <h3>General Information</h3>
                <div className="top">
                  <div className="section">
                    <h3>Meals</h3>
                    <div className="label">
                      <label>Meals: </label>
                      <p>{JSON.parse(nutritionPlan.meals).quantity}</p>
                    </div>
                    <div className="label">
                      <label>Protein: </label>
                      <p>{JSON.parse(nutritionPlan.meals).proteinEach}</p>
                    </div>
                    <div className="label">
                      <label>Carbs: </label>
                      <p>{JSON.parse(nutritionPlan.meals).carbsEach}</p>
                    </div>
                    <div className="label">
                      <label>Fats: </label>
                      <p>{JSON.parse(nutritionPlan.meals).fatsEach}</p>
                    </div>
                  </div>
                  <div className="section">
                    <h3>Macros</h3>
                    <div className="label">
                      <label>Protein: </label>
                      <p>{JSON.parse(nutritionPlan.macros).protein}</p>
                    </div>
                    <div className="label">
                      <label>Carbs: </label>
                      <p>{JSON.parse(nutritionPlan.macros).carbs}</p>
                    </div>
                    <div className="label">
                      <label>Fats: </label>
                      <p>{JSON.parse(nutritionPlan.macros).fats}</p>
                    </div>
                  </div>
                  <div className="section">
                    <h3>Fat Sources</h3>
                    <div className="label">
                      <label>Oils: </label>
                      <p>{JSON.parse(nutritionPlan.fats).oils}</p>
                    </div>
                    <div className="label">
                      <label>Nuts: </label>
                      <p>{JSON.parse(nutritionPlan.fats).nuts}</p>
                    </div>
                    <div className="label">
                      <label>Nut Butters: </label>
                      <p>{JSON.parse(nutritionPlan.fats).nb}</p>
                    </div>
                    <div className="label">
                      <label>Avocado: </label>
                      <p>{JSON.parse(nutritionPlan.fats).avocado}</p>
                    </div>
                    <div className="label">
                      <label>Butter: </label>
                      <p>{JSON.parse(nutritionPlan.fats).butter}</p>
                    </div>
                  </div>
                </div>
                <h3>Meals</h3>
                <div className="mealList">
                  <MealList
                    mealData={JSON.parse(nutritionPlan.plan)}
                    count={JSON.parse(nutritionPlan.meals).quantity}
                    clientContext={true}
                  />
                </div>
              </>
            ) : (
              <>
                <h3>Notes</h3>

                <p>General Program Notes:</p>
                <textarea
                  className="notes"
                  id="notes"
                  placeholder="notes"
                  defaultValue={nutritionPlan.notes}
                  style={{height: 450}}
                />

                <button type="button" style={buttonStyles} onClick={handleAddNutritionNote()}>Add Note</button>
                {
                  displayMoreNotes
                    ? (nutritionNotes ?? []).map((weeklyNote, noteIdx) => {
                      return (
                        <NoteEntryBox key={`${weeklyNote._id}-${noteIdx}`} providedNoteObj={weeklyNote} emitSaveNote={handleSaveNutritionNote} emitDeleteNote={handleDeleteNutritionNote} />
                      )
                    })
                    :
                    nutritionNotes.length == 0
                      ? <p>Client has no Nutrition Notes</p>


                      : (nutritionNotes ?? []).map((weeklyNote, noteIdx) => {
                        if (noteIdx > 1 + displayMoreNotesCount * 4) {
                          return;
                        }
                        return (
                          <NoteEntryBox key={`${weeklyNote._id}-${noteIdx}`} providedNoteObj={weeklyNote} emitSaveNote={handleSaveNutritionNote} emitDeleteNote={handleDeleteNutritionNote} />
                        )
                      })

                }
                {
                  (nutritionNotes.length > 2 && nutritionNotes.length > (displayMoreNotesCount) * 4 + 2)
                    ? <button type="button" style={buttonStyles} onClick={handleChangeShowMore()}>{/*displayMoreNotesText*/ "More"}</button>
                    : <></>
                }
                <div className="top">
                  <div className="section">
                    <h3>Meals</h3>
                    <div className="label">
                      <label>Meals: </label>
                      <input
                        onChange={handleCount}
                        id="meals-quantity"
                        type="number"
                        placeholder="number"
                        defaultValue={JSON.parse(nutritionPlan.meals).quantity}
                      />
                    </div>
                    <div className="label">
                      <label>Protein: </label>
                      <input
                        id="meals-protein"
                        type="text"
                        placeholder="protein"
                        defaultValue={
                          JSON.parse(nutritionPlan.meals).proteinEach
                        }
                      />
                    </div>
                    <div className="label">
                      <label>Carbs: </label>
                      <input
                        id="meals-carbs"
                        type="text"
                        placeholder="carbs"
                        defaultValue={JSON.parse(nutritionPlan.meals).carbsEach}
                      />
                    </div>
                    <div className="label">
                      <label>Fats: </label>
                      <input
                        id="meals-fats"
                        type="text"
                        placeholder="fats"
                        defaultValue={JSON.parse(nutritionPlan.meals).fatsEach}
                      />
                    </div>
                  </div>
                  <div className="section">
                    <h3>Macros</h3>
                    <div className="label">
                      <label>Protein: </label>
                      <input
                        id="macro-protein"
                        type="text"
                        placeholder="protein"
                        defaultValue={JSON.parse(nutritionPlan.macros).protein}
                      />
                    </div>
                    <div className="label">
                      <label>Carbs: </label>
                      <input
                        id="macro-carbs"
                        type="text"
                        placeholder="carbs"
                        defaultValue={JSON.parse(nutritionPlan.macros).carbs}
                      />
                    </div>
                    <div className="label">
                      <label>Fats: </label>
                      <input
                        id="macro-fats"
                        type="text"
                        placeholder="fats"
                        defaultValue={JSON.parse(nutritionPlan.macros).fats}
                      />
                    </div>
                  </div>

                  <div className="section">
                    <h3>Fat Sources</h3>
                    <div className="label">
                      <label>Oils: </label>
                      <input
                        id="fats-oils"
                        type="text"
                        placeholder="oils"
                        defaultValue={JSON.parse(nutritionPlan.fats).oils}
                      />
                    </div>
                    <div className="label">
                      <label>Nuts: </label>
                      <input
                        id="fats-nuts"
                        type="text"
                        placeholder="nuts"
                        defaultValue={JSON.parse(nutritionPlan.fats).nuts}
                      />
                    </div>
                    <div className="label">
                      <label>Nut Butters: </label>
                      <input
                        id="fats-nutbutters"
                        type="text"
                        placeholder="nutbutters"
                        defaultValue={JSON.parse(nutritionPlan.fats).nb}
                      />
                    </div>
                    <div className="label">
                      <label>Avocado: </label>
                      <input
                        id="fats-avocado"
                        type="text"
                        placeholder="avocado"
                        defaultValue={JSON.parse(nutritionPlan.fats).avocado}
                      />
                    </div>
                    <div className="label">
                      <label>Butter: </label>
                      <input
                        id="fats-butter"
                        type="text"
                        placeholder="butter"
                        defaultValue={JSON.parse(nutritionPlan.fats).butter}
                      />
                    </div>
                  </div>
                </div>
                <div className="mealList">
                  <MealList mealData={mealData} count={count} />
                </div>
              </>
            )}
          </>
        ) : null}
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default PortalNutritionPlanningModule;



function NoteEntryBox(props/*: string*/) {

  const { providedNoteObj, emitSaveNote, emitDeleteNote } = props;

  const [editingText, setEditingText] = useState(!providedNoteObj.weekOf);
  const [note, setNote] = useState(providedNoteObj.note ?? '');
  const [weekOf, setWeekOf] = useState(providedNoteObj.weekOf ?? 'Save to set Week-of Date');

  function runSetEditingText(boolVal /*boolVal */) {
    //unsure why react wants us to do it this way. https://www.learnbestcoding.com/post/69/why-react-onclick-function-fires-on-render#google_vignette
    return () => {
      console.log('edit text 1')
      setEditingText(boolVal)
      console.log('edit text 2')
    }
  }



  function cancelEdit() {
    return () => {
      setEditingText(false);
      //Item is being set, though it's not rerendering correctly. Unsure exactly why
      setNote(providedNoteObj.note);
    }
  }

  function handleChange(event) {
    setNote(event.target.value);
  };

  function handleSave() {
    return () => {
      setEditingText(false);
      emitSave()
    }
  }

  function emitSave() {
    emitSaveNote({
      ...providedNoteObj,
      note: note,
      // weekOf: weekOf, Don't need this since we ask for it after this call
    });

  }

  function handleDelete() {
    return () => {
      emitDelete();
    }
  }

  function emitDelete() {
    emitDeleteNote(providedNoteObj);
  }

  return (

    <>
      <div style={{width: '100%'}}>
        <div style={{ ...noteEntryBoxStyles, justifyContent: 'space-between', width: '100%', alignItems: 'flex-end' }}>
          <p>{weekOf ?? 'Save note to change week'}</p>
          <div style={{ ...noteEntryBoxStyles }}>
            {
              editingText
                ? <>
                  <button type="button" style={{ ...buttonStyles, backgroundColor: '#009933' }} onClick={handleSave()}>Save</button>
                  {/* Can't get this to work right now... */}
                  {/* <button type="button" style={buttonStyles} onClick={cancelEdit()}>Cancel</button> */}
                </>
                : <button type="button" style={buttonStyles} onClick={runSetEditingText(true)}>Edit</button>
            }
            <button type="button" style={buttonStyles} onClick={handleDelete()}>Delete</button>
          </div>
        </div>
        <textarea readOnly={!editingText} defaultValue={note} onChange={handleChange}></textarea>
      </div>
    </>

  )
}


//Styles
const noteEntryBoxStyles = {
  display: 'flex',
  flexDirection: 'row',
}

const buttonStyles = {
  maxWidth: 100,
  marginLeft: 20,
}


// const styleSheet = {
//   notesContainer: {
//     width: '100%',
//   },
//   portalNutritionPlanModule: {
//     width: '90%',
//     height: 150,
//     overflow: 'hidden',
//   },
//   buttonStyles: {
//     maxWidth: 100,
//     marginLeft: 20,
//   }
// }
